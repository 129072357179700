/* You can add global styles to this file, and also import other style files */

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
	background-color: #f8f5f4;
}

:root {
	--black: #000;
	--gray: #808080;
	--gray200: #545454;
	--red: #BE2A34;
	--bg-body: #f8f5f4;
	--blue: #1E3276;
}

.gray {
	color: var(--gray);

	&_200 {
		color: var(--gray200) !important;
	}
}

.red {
	color: var(--red);
}

.blue {
	color: var(--blue);
}

.font-weight {
	&_100 {
		font-weight: 100 !important;
	}

	&_200 {
		font-weight: 200 !important;
	}

	&_300 {
		font-weight: 300 !important;
	}

	&_400 {
		font-weight: 400 !important;
	}

	&_500 {
		font-weight: 500 !important;
	}

	&_600 {
		font-weight: 600 !important;
	}

	&_700 {
		font-weight: 700 !important;
	}

	&_800 {
		font-weight: 800 !important;
	}

	&_900 {
		font-weight: 900 !important;
	}
}

.pointer {
	cursor: pointer;
}

/*
* ====================================================================
* = buttons
* ====================================================================
*/
button:focus {
	outline: none;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
	background-color: #EE8089;
	color: #fff;
}

/* Estilo para los botones prev y next del carrousel slick*/
.slick-prev,
.slick-prev:hover,
.slick-prev:focus,
.slick-next,
.slick-next:hover,
.slick-next:focus {
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	z-index: 99;
	box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
	background-image: url('assets/svg/prev.svg');
	background-color: white;
	left: -4px;
}

.slick-next,
.slick-next:hover,
.slick-next:focus {
	background-image: url('assets/svg/next.svg');
	background-color: white;
	right: -4px;
}


.slick-prev:before,
.slick-next:before {
	content: '';
}

.slick-slider {
	margin: 0 -15px;
}

.slick-slide {
	margin-right: 15px;
	margin-left: 15px;
}

/*
 * ====================================================================
 * = Scrollbar
 * ====================================================================
 */
.scroll {
	overflow: auto;

	&.scroll-none {
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
		}
	}

	&::-webkit-scrollbar {
		width: 5px;
		height: 0px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(78, 178, 244, 0.2);
		border-radius: 10px;
	}
}

/*
* ====================================================================
* = progress bar
* ====================================================================
*/
.mat-progress-bar-buffer {
	background-color: #DADADA;
}

.mat-progress-bar-fill::after {
	background-color: var(--red);
}

/*
* ====================================================================
* = images
* ====================================================================
*/
.scale-down {
	object-fit: scale-down;
}

.cover {
	object-fit: cover;
}

/*
* ====================================================================
* = mat-form-field
* ====================================================================
*/
.mat-form-field-flex {
	background: #fff;
}

/*
* ====================================================================
* = mat-dialog
* ====================================================================
*/
@media (max-width: 576px) {
	.cdk-overlay-pane{
	  &.sm-full{
		width: 98vw!important;
		max-width: 98vh!important;
		/* height: 95vh!important;
		max-height: 95vh!important; */
  
		.mat-dialog-container {
		  padding: 10px!important;
		}
	  }
	}
  }